// src/components/Headline.js
import React, { useState, useEffect, useContext, useRef } from "react";

const Headline = () => {
  const videoRef = useRef();

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Failed to play video:", error);
        });
      }
    };

    playVideo(); // Try to autoplay

    // Add event listener for user interaction fallback
    document.addEventListener("click", playVideo);

    return () => {
      document.removeEventListener("click", playVideo);
    };
  }, []);

  return (
    <div className="hero min-h-screen mt-20 bg-base-100">
      <div className="hero-content flex-col lg:flex-row justify-between">
        <div className="sm:w-1/2 sm:mr-20">
          <h1 className="text-5xl font-bold">
            Get Email Summaries by Forwarding
            {/* <i className="fas fa-envelope cursor-pointer text-lg"></i> */}
          </h1>
          <div>
            <p className="py-6 text-xl">
              Forward your emails to FwdMailer and get AI summaries quickly:
            </p>
            <a
              href="mailto:summarize@fdwmailer.com"
              className="text-xl text-primary"
            >
              {" "}
              summarize@fwdmailer.com
            </a>
            <br />
          </div>
          <br />
          <a
            //className="btn btn-md border"
            className="btn btn-md bg-primary text-white"
            href="/#pricing"
          >
            {/* <i className="fab fa-chrome text-xl"></i> */}
            See Pricing
            <i className="fas fa-arrow-down"></i>
          </a>{" "}
        </div>
        <video
          className="sm:max-w-sm rounded-lg shadow-2xl"
          ref={videoRef}
          width="280em"
          // height="100em"
          //   muted
          //   playsInline
          //   autoPlay
          //   loop
          autoPlay
          loop
          muted
          playsInline
          onCanPlay={() => {
            videoRef.current.playbackRate = 1;
          }}
        >
          <source
            src="https://quotebucket-2023.s3.us-west-1.amazonaws.com/fwdmailer-demo-1.0.0.MOV"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Headline;
