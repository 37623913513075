// src/components/Footer.js
import React from "react";
//import LanterLogo from "../../../img/lanter-logo.png";

const Footer = () => {
  return (
    <footer className="footer footer-center p-7 bg-primary rounded text-white">
      {/* <img src={LanterLogo} className="w-44 m-0" alt="Lanter logo" /> */}
      {/* <div className="flex ">
        <a href="https://discord.gg/fk94UqZsXv">
          <i className="fab fa-discord cursor-pointer text-lg"></i>
        </a>

        <a href="mailto:bayram@lanterapp.com?subject=Question%20-%20Feedback%20about%20Lanter&body=Hi%20there%2C%0A%0A">
          <i className="fas fa-envelope cursor-pointer text-lg"></i>
        </a>
      </div> */}

      {/* <nav className="grid grid-flow-col gap-4">
        <a className="link link-hover">About us</a>
        <a className="link link-hover">Contact</a>
        <a className="link link-hover" href="/terms">
          Terms of use
        </a>
        <a className="link link-hover" href="/privacy">
          Privacy policy
        </a>
      </nav> */}
      <aside>
        <p>Copyright © 2024 - All right reserved by FwdMailer</p>
      </aside>
    </footer>
  );
};
{
  /* <p>© 2024 InsightCapture. All rights reserved.</p> */
}

export default Footer;
