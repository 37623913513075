import React, { Fragment, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
/* Tailwind landing */
import Landing from "./components/Landing";
import ReactGa from "react-ga";
// import "./App.css";
import "./Tailwind.css";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import TermsOfService from "./components/TermsOfService";
// import Alert from "./components/misc/Alert";

function App() {
  // useEffect(() => {
  //   ReactGa.initialize("UA-179412951-2");

  //   // to report page views

  //   ReactGa.pageview("/");
  // }, []);

  useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
  });
  return (
    <Router>
      <section className="container">
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/pricing" component={<PricingRedirect />} />

          {/* <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsOfService} /> */}
        </Routes>
      </section>
    </Router>
  );
}
const PricingRedirect = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/#pricing");
  }, [navigate]);

  return null;
};
export default App;
