import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import Headline from "./Headline";
//import Features from "./Features";
import Pricing from "./Pricing";
import Footer from "./Footer";

const Landing = () => {
  return (
    <div>
      <Navbar />
      <Headline />
      {/* <Features /> */}
      <Pricing />
      <Footer />
    </div>
  );
};
export default Landing;
