// src/components/Navbar.js
import React from "react";
//import LanterLogo from "../../../img/lanter-logo.png";

const Navbar = () => {
  return (
    <div className="navbar h-3 fixed top-0 left-0 right-0 z-50 bg-white backdrop-blur-lg bg-opacity-75 border-b-2">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <a href="/#pricing">Pricing</a>
            </li>
            {/* <li>
              <a href="/#features">Features</a>
            </li> */}
            {/* <li>
              <a>Contact</a>
            </li>
            <li>
              <a>FAQ</a>
            </li> */}
          </ul>
        </div>
        {/* <a className=" text-xl" href="/">
          <img
            src={LanterLogo}
            // className="logo-icon"
            alt="quotebucket logo"
            style={{
              width: "10em",
              padding: 0,
            }}
          />
        </a> */}
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 text-base">
          <li>
            <a href="/#pricing">Pricing</a>
          </li>
          {/* <li>
            <a href="/#features">Features</a>
          </li> */}
          {/* <li>
            <a>Contact</a>
          </li>
          <li>
            <a>FAQ</a>
          </li> */}
        </ul>
      </div>
      <div className="navbar-end">
        {/* <a
          //className="btn btn-md border"
          className="btn btn-md bg-primary text-white"
          href="https://chrome.google.com/webstore/detail/lanter-youtube-highlighte/dddkbkfbiikdebcdafdnjcimkooehmfb"
        >
          <i className="fab fa-chrome text-lg"></i> Try Lanter Free
          <i className="fas fa-arrow-right"></i>
        </a>{" "} */}
      </div>
    </div>
  );
};

export default Navbar;
